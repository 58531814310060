/* body {
  background-color: #f2f3f6;
} */

.alerts-tabs-div {
  padding: 10px;
  background-color: #f5f5f5;
  cursor: pointer;
}

.alerts-tab-div-active {
  padding: 10px;
  background-color: #fff;
  cursor: pointer;
}

.alerts-tabs-active {
  margin-bottom: 0px;
  font-size: 13px;
  font-weight: 600;
  font-family: "Open sans";
  letter-spacing: 0px;
  color: #1648aa;
  text-align: center;
}

.alerts-tabs {
  margin-bottom: 0px;
  font-size: 13px;
  font-weight: 600;
  font-family: "Open sans";
  letter-spacing: 0px;
  color: #464646;
  text-align: center;
}

.searchInput,
.searchInput:hover,
.searchInput:focus {
  border: 0.5px solid #d7dade !important;
  border-radius: 3px;
  box-shadow: none !important;
}

.searchInput::placeholder {
  font-size: 14px;
  font-family: "Open sans";
  letter-spacing: 0px;
  color: #0c090a;
  opacity: 0.42;
}

.selectedItem {
  margin-right: 9px;
  margin-top: 3px;
  padding: 19px 0px;
  background-color: #07888d;
}

.alertName {
  margin-bottom: 0px;
  font-size: 13px;
  font-weight: 600;
  font-family: "Open sans";
  letter-spacing: 0px;
  color: #000000;
}

.alertLoc {
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: normal;
  font-family: "Open sans";
  letter-spacing: 0px;
  color: #464646;
}

.selectedPerson {
  margin-bottom: 0px;
  font-size: 18px;
  font-family: "Open sans";
  letter-spacing: -0.5px;
  color: #161537;
  opacity: 1;
}

.status {
  margin-bottom: 0px;
  font-size: 12px;
  font-family: "Open sans";
  letter-spacing: 0px;
  color: #080707;
  opacity: 1;
}

.personStatus {
  margin-bottom: 20px;
  font-size: 12px;
  font-family: "Open sans";
  letter-spacing: 0px;
  color: #080707;
  opacity: 1;
  text-align: center;
}

.alertTitle {
  margin-bottom: 0px;
  font-size: 18px;
  font-family: "Open sans";
  letter-spacing: 0px;
  color: #161537;
  opacity: 1;
}

.this-is-to {
  margin-bottom: 25px;
  margin-top: 20px;
  font-size: 13px;
  font-family: "Open sans";
  letter-spacing: 0.45px;
  color: #0f1014;
  opacity: 1;
}

.details-title {
  margin-bottom: 0px;
  font-size: 13px;
  font-weight: 600;
  font-family: "Open sans";
  letter-spacing: 0.45px;
  color: #0f1014;
  opacity: 1;
}

.details-value {
  font-size: 13px;
  font-weight: normal;
  font-family: "Open sans";
  letter-spacing: 0.45px;
  color: #0f1014;
  opacity: 1;
}

.typing-input,
.typing-input:hover,
.typing-input:focus {
  padding: 10px;
  border: 1px solid #00000021 !important;
  border-radius: 3px;
  box-shadow: none !important;
  opacity: 1;
}

.new-person-title {
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 600;
  font-family: "Open sans";
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.mail-address {
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: normal;
  font-family: "Open sans";
  letter-spacing: 0px;
  color: #464646;
}

.newRequest {
  margin-bottom: 0px;
  font-size: 12px;
  font-weight: normal;
  font-family: "Open sans";
  letter-spacing: 0px;
  color: #1648aa;
}

.accept-request-div {
  margin: 0px -10px 15px;
  padding: 10px 20px;
  background-color: #089ea43b;
  border: 0.5px solid #bcdedf;
  border-radius: 4px;
}

.request-person-msg {
  margin-bottom: 0px;
  font-size: 13px;
  font-weight: normal;
  font-family: "Open sans";
  letter-spacing: 0px;
  color: #464646;
}

.ignore-btn,
.ignore-btn:hover,
.ignore-btn:focus {
  background-color: transparent;
  border: none;
  font-size: 10px;
  font-weight: 600;
  font-family: "Open sans";
  letter-spacing: 0px;
  color: #464646;
  box-shadow: none !important;
  outline: none;
}

.accept-btn,
.accept-btn:hover,
.accept-btn:focus {
  background-color: #089ea4;
  border: 0.5px solid #07888d !important;
  border-radius: 2px;
  font-size: 10px;
  font-weight: 600;
  font-family: "Open sans";
  letter-spacing: 0px;
  color: #ffffff;
  box-shadow: none !important;
  outline: none;
}

